import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import SimpleIntro from "../components/global/simple-intro"
import { navigate } from "gatsby"
import { toast } from "react-toastify"
import LoginForm from "../components/login/login-form"
import RegisterForm from "../components/register/register-form"
import Button from "../components/button/button"
import { AuthContext } from "../context/AuthContext"
import CheckoutAuthLayout from "../components/checkout/checkout-auth-layout"

const CheckoutAuthPage = ({ component: Component, location, ...rest }) => {
  const [wineryId, setWineryId] = useState([])
  const [isRegistered, setIsRegistered] = useState(false)
  const [redirecting, setRedirecting] = useState(false)

  const { contextIsUserLoggedIn, handleContextCheckLoggedIn, isGuestUser } =
    useContext(AuthContext)

  handleContextCheckLoggedIn()

  useEffect(() => {
    if (location.state && location.state.winery_id) {
      setWineryId(location.state.winery_id)
      if (contextIsUserLoggedIn) {
        navigate("/checkout", {
          state: { winery_id: location.state.winery_id },
        })
      }
    } else {
      navigate("/cart")
    }
  }, [])

  const handleLoggedIn = () => {
    setRedirecting(true)
    navigate("/checkout", {
      state: { winery_id: wineryId },
    })
  }

  const handleRegistered = () => {
    toast.success("Registration successful. Please login to continue.")
    setIsRegistered(true)
  }

  return (
    <div>
      {!contextIsUserLoggedIn && (
        <Layout>
          <Container gutters width={"small"}>
            <SimpleIntro title={"Checkout"} />
          </Container>

          <Container gutters width={"small"} pb={2} textAlign="right">
            <Button disabled={redirecting} primary onClick={handleLoggedIn}>
              <div>{redirecting ? "Redirecting..." : "Continue as guest"}</div>
            </Button>
          </Container>
          <Container gutters width={"small"} pb={3}>
            <CheckoutAuthLayout>
              <LoginForm handleLoggedIn={handleLoggedIn} />
              {!!isGuestUser && (
                <RegisterForm
                  handleRegistered={handleRegistered}
                  isGuestUser={isGuestUser}
                />
              )}
            </CheckoutAuthLayout>
          </Container>
        </Layout>
      )}
    </div>
  )
}
export default CheckoutAuthPage

import React, { useState } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { registerUser } from "../../services/register"
import Container from "../container/container"
import Input from "../form/Input"
import { toast } from "react-toastify"
import Button from "../button/button"
import FormWrapper from "../form/form-wrapper"
import { Link } from "gatsby"

const schema = yup
  .object({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    password: yup.string().required("Password Name is required"),
    confirm_password: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required()

const RegisterForm = ({
  component: Component,
  location,
  handleRegistered,
  isGuestUser,
}) => {
  const methods = useForm({
    resolver: yupResolver(schema),
  })
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = methods

  const [generalMsg, setGeneralMsg] = useState("")
  const [processingForm, setProcessingForm] = useState(false)

  const sendMc = async (data) => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")

    const sendData = {
      email_address: data.email,
    }

    const response = await fetch(process.env.GATSBY_MAKE_REGISTER_WEB_HOOK, {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(sendData),
    }).then((res) => {
      return
    })
  }

  const onSubmit = async (data) => {
    setProcessingForm(true)

    registerUser(data).then((res) => {
      if (res.status) {
        sendMc(data)
        handleRegistered()
      } else if (res.message) {
        toast.error(res.message)
      }
      setProcessingForm(false)
    })
  }

  return (
    <Container pt={0} pb={3}>
      <FormWrapper
        heading={"Register"}
        text={"Please register if you don't have an account"}
      >
        <FormProvider {...methods}>
          <form
            className="form__register"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Input
              label={"Email"}
              name="email"
              {...register("email")}
              required
              placeholder={"Your Email"}
              errorMessage={errors.email?.message}
            />
            <Input
              label={"Password"}
              name="password"
              type="password"
              {...register("password")}
              required
              placeholder={"Your Password"}
              errorMessage={errors.password?.message}
            />
            <p>
              Password must be between 6-12 characters including one capital,
              one small letter and one number.
            </p>
            <Input
              label={"Confirm Password"}
              name="confirm_password"
              type="password"
              {...register("confirm_password")}
              required
              placeholder={"Confirm Your Password"}
              errorMessage={errors.confirm_password?.message}
            />
            <Container pt={1}>
              <Button primary fullWidth type="submit">
                {processingForm ? "Processing...." : "Submit"}
              </Button>
              {isGuestUser && (
                <Container pt={1} textAlign="left">
                  <Link to="/forgot-password-request">
                    <small>
                      If you've previously been a guest on iHeart Wine your
                      email will be recognised by our system. Please click reset
                      password and follow the prompts to continue with your
                      order.
                    </small>
                  </Link>
                </Container>
              )}
            </Container>
          </form>
        </FormProvider>
      </FormWrapper>
    </Container>
  )
}
export default RegisterForm
